import z from 'zod'
import { DocumentReference } from 'firebase/firestore'
import { GetEntitySchema } from './network'

export const EventBaseSchema = z.object({
  uid: z.string(),
  name: z
    .string()
    .min(3, 'Nombre debe tener al menos 3 caracteres')
    .max(50, 'Nombre debe tener como máximo 50 caracteres')
    .trim(),
  amount: z.number().positive('Cantidad debe ser mayor a 0').optional(),
  description: z.string().max(500, 'Descripción debe tener como máximo 500 caracteres').trim(),
  date: z.number(),
  deleted: z.boolean().optional(),
  attachments: z.array(z.string()).max(4, 'Máximo 4 imágenes'),
  createdAt: z.number(),
  updatedAt: z.number(),
})

export type EventBaseType = z.infer<typeof EventBaseSchema>

// Internal use
export const EventFirebaseSchema = EventBaseSchema.extend({
  client: z.custom<DocumentReference>(),
})

export type EventFirebaseType = z.infer<typeof EventFirebaseSchema>

// Create
export const CreateEventSchema = z.object({
  clientID: z.string(),
  data: EventBaseSchema.omit({
    uid: true,
    deleted: true,
    createdAt: true,
    updatedAt: true,
  }),
})

export type CreateEventType = z.infer<typeof CreateEventSchema>

// Update
export const UpdateEventSchema = z.object({
  clientID: z.string(),
  data: EventBaseSchema.omit({
    deleted: true,
    createdAt: true,
    updatedAt: true,
  }),
})

export type UpdateEventType = z.infer<typeof UpdateEventSchema>

// Delete
export const DeleteEventsSchema = z.object({
  clientID: z.string(),
  uids: z.array(z.string()),
})

export type DeleteEventsType = z.infer<typeof DeleteEventsSchema>

// Search
export const SearchEventSchema = GetEntitySchema.extend({
  startDate: z.number().optional(),
  endDate: z.number().optional(),
})

export type SearchEventType = z.infer<typeof SearchEventSchema>

// Get events
export const GetEventsSchema = GetEntitySchema.extend({
  clientID: z.string(),
})

export type GetEventsType = z.infer<typeof GetEventsSchema>

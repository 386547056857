import moment from 'moment'
import * as XLSX from 'xlsx'
import { v4 as uuidv4 } from 'uuid'

import {
  doc,
  limit,
  query,
  where,
  getDoc,
  setDoc,
  getDocs,
  orderBy,
  updateDoc,
  deleteDoc,
  collection,
  writeBatch,
  startAfter,
  type QuerySnapshot,
  type QueryConstraint,
  runTransaction,
  DocumentReference,
} from 'firebase/firestore'

import { Collections } from '@constants/api'

import {
  GetProformasType,
  ProformaBaseType,
  UpdateProformaType,
  CreateProformaType,
  ProformaFirebaseType,
  CustomerProformaType,
} from '@customTypes/proforma'
import { ToolBaseType } from '@customTypes/tool'
import { GetExportEntitiesBulkType, GetExportEntityType } from '@customTypes/export'
import { BlockFirebaseType, BlockType } from '@customTypes/block'
import { ClientBaseType, ClientFirebaseType } from '@customTypes/client'
import { ReceiptsFirebaseType, ReceiptType } from '@customTypes/receipt'
import { WarehouseFirebaseType, WarehouseType } from '@customTypes/warehouse'
import { RawMaterialBaseType, RawMaterialFirebaseType } from '@customTypes/rawMaterial'
import { WarehouseToolFirebaseType, WarehouseToolType } from '@customTypes/warehouseTool'

import DocumentEntitiesDS from '@api/domain/ds/DocumentEntitiesDS'

import { Logger } from '@utils/log'
import { formatNumber } from '@utils/string'
import { getProformaTotal } from '@utils/proforma'
import { ErrorCodes, ErrorService } from '@utils/error'
import { firestore, getSequential, getComposedIDs, parseRefToEntity } from '@utils/firebase'

import ClientEntityImpl from './ClientEntityImpl'
import WarehouseEntitiesImpl from './WarehouseEntitiesImpl'
import ConstructionEntitiesImpl from './ConstructionEntitiesImpl'
import { DATE_FORMAT_SHORT } from '@constants/app'
import { ExportEntities } from '@constants/export'
import {
  CreateOrderPurchaseType,
  GetOrderPurchasesType,
  OrderPurchaseFirebaseType,
  OrderPurchaseType,
  UpdateOrderPurchaseType,
} from '@customTypes/orderPurchase'
import { QtyBaseOrderType } from '@customTypes/quantity'

type ParseSheetsEntity = {
  arrays: (string | number)[][]
  sheetName: string
}

class DocumentEntitiesImpl extends DocumentEntitiesDS {
  private clientEntityService = ClientEntityImpl.getInstance()
  private warehouseEntitiesService = WarehouseEntitiesImpl.getInstance()
  private constructionEntitiesService = ConstructionEntitiesImpl.getInstance()

  static instance: DocumentEntitiesImpl

  private constructor() {
    super()
  }

  public static getInstance() {
    if (!this.instance) {
      this.instance = new DocumentEntitiesImpl()
    }

    return this.instance
  }

  //Proforma
  async parseToProformaBase(proformaFirebase: ProformaFirebaseType) {
    const {
      uid,
      sequential,
      name,
      description,
      clientRef,
      items,
      status,
      updatedAt,
      createdAt,
      dueDate,
      iva,
      payments,
      validUntil,
      warranty,
      discount,
    } = proformaFirebase

    let client: CustomerProformaType | undefined | null = proformaFirebase.client

    if (clientRef) {
      const clientData = await parseRefToEntity<ClientFirebaseType>(clientRef)
      client = {
        uid: clientData.uid,
        ruc: clientData.ruc ?? '',
        name: clientData.name,
        email: clientData.email,
        phone: clientData.phone,
        location: clientData.location,
        contacts: clientData.contacts,
      }
    }

    const proformaBase: ProformaBaseType = {
      uid,
      sequential,
      name,
      description,
      items,
      updatedAt,
      createdAt,
      status,
      client: client!,
      dueDate,
      iva,
      discount,
      payments,
      validUntil,
      warranty,
    }

    return proformaBase
  }

  async getProformas(params: GetProformasType) {
    try {
      const collectionRef = collection(firestore, Collections.PROFORMAS.NAME)

      const queries: QueryConstraint[] = []

      if (params.cursorId) {
        const docRef = doc(collectionRef, params.cursorId)
        queries.push(startAfter(docRef))
        params.limit && queries.push(limit(params.limit))
      }

      queries.push(orderBy('updatedAt', params.orderDir ?? 'desc'))

      const q = query(collectionRef, ...queries)

      const proformasSnap = await getDocs(q)

      const proformasData = proformasSnap.docs.map((doc) => doc.data()) as ProformaFirebaseType[]

      const proformasBase: ProformaBaseType[] = []

      for (const proforma of proformasData) {
        proformasBase.push(await this.parseToProformaBase(proforma))
      }

      return proformasBase
    } catch (error) {
      Logger.error('Error getting proformas', error)
      throw ErrorService.get(ErrorCodes.ERROR_GETTING_PROFORMAS)
    }
  }

  async getProformaById(id: string) {
    try {
      // const dataByID = queryClient.getQueryData<ProformaBaseType>([QueryKeys.GET_PROFORMA_KEY, id])

      // if (dataByID) {
      //   return dataByID
      // }

      // const results = queryClient.getQueriesData<ProformaBaseType[]>({
      //   predicate: (query) => query.queryKey[0] === QueryKeys.GET_PROFORMAS_KEY,
      // })

      // const data = this.findItemOnResults(results, id)

      // if (data) {
      //   return data
      // }

      const docRef = doc(firestore, Collections.PROFORMAS.NAME, id)

      const proformaSnap = await getDoc(docRef)

      const proformaFirebaseData = proformaSnap.data() as ProformaFirebaseType

      return this.parseToProformaBase(proformaFirebaseData)
    } catch (error) {
      Logger.error('Error getting proforma by id', error)
      throw ErrorService.get(ErrorCodes.ERROR_GETTING_PROFORMAS)
    }
  }

  async createProforma(data: CreateProformaType) {
    try {
      const currentTimestamp = moment().unix()

      const sequential = await getSequential({ entity: 'proforma' })

      const newProforma: ProformaFirebaseType = {
        uid: uuidv4(),
        sequential,
        name: data.name,
        description: data.description,
        status: data.status,
        items: data.items,
        createdAt: currentTimestamp,
        updatedAt: currentTimestamp,
        client: data.client,
        dueDate: data.dueDate,
        iva: data.iva,
        discount: data.discount,
        payments: data.payments,
        validUntil: data.validUntil,
        warranty: data.warranty,
      }

      if (data.clientID) {
        newProforma.clientRef = doc(firestore, Collections.CLIENTS.NAME, data.clientID)
      } else {
        newProforma.client = data.client
      }

      await setDoc(doc(firestore, Collections.PROFORMAS.NAME, newProforma.uid), newProforma)

      let client: CustomerProformaType | null = null

      if (newProforma.clientRef) {
        const clientData = await parseRefToEntity<ClientFirebaseType>(newProforma.clientRef)
        client = {
          uid: clientData.uid,
          ruc: clientData.ruc ?? '',
          name: clientData.name,
          email: clientData.email,
          phone: clientData.phone,
          location: clientData.location,
          contacts: clientData.contacts,
        }
      } else if (newProforma.client) {
        client = newProforma.client
      }

      const proformaData: ProformaBaseType = {
        ...newProforma,
        client: client!,
      }

      return proformaData
    } catch (error) {
      Logger.error('Error creating proforma', error)
      throw ErrorService.get(ErrorCodes.ERROR_CREATING_PROFORMA)
    }
  }

  async updateProforma(data: UpdateProformaType) {
    try {
      const updateProforma: Partial<ProformaFirebaseType> = {}

      if (data.name) {
        updateProforma.name = data.name
      }

      if (data.description) {
        updateProforma.description = data.description
      }

      if (data.clientID) {
        updateProforma.clientRef = doc(firestore, Collections.CLIENTS.NAME, data.clientID)
        updateProforma.client = null
      } else {
        updateProforma.clientRef = null
        updateProforma.client = data.client
      }

      if (data.items) {
        updateProforma.items = data.items
      }

      if (data.status) {
        updateProforma.status = data.status
      }

      if (data.dueDate) {
        updateProforma.dueDate = data.dueDate
      }

      if (data.iva) {
        updateProforma.iva = data.iva
      }

      if (data.payments) {
        updateProforma.payments = data.payments
      }

      if (data.validUntil) {
        updateProforma.validUntil = data.validUntil
      }

      if (data.warranty) {
        updateProforma.warranty = data.warranty
      }

      if (data.discount) {
        updateProforma.discount = data.discount
      }

      if (Object.keys(updateProforma).length === 0) {
        throw ErrorService.get(ErrorCodes.ERROR_NOT_FIELDS_TO_UPDATE)
      }

      const docRef = doc(firestore, Collections.PROFORMAS.NAME, data.uid)

      await updateDoc(docRef, updateProforma)
    } catch (error) {
      Logger.error('Error updating proforma', error)
      throw ErrorService.get(ErrorCodes.ERROR_UPDATING_PROFORMA)
    }
  }

  async deleteProforma(id: string) {
    try {
      const docRef = doc(firestore, Collections.PROFORMAS.NAME, id)

      await deleteDoc(docRef)
    } catch (error) {
      Logger.error('Error deleting proforma', error)
      throw ErrorService.get(ErrorCodes.ERROR_DELETING_PROFORMA)
    }
  }

  async deleteProformas(ids: string[]) {
    try {
      const batch = writeBatch(firestore)

      ids.forEach((id) => {
        const docRef = doc(firestore, Collections.PROFORMAS.NAME, id)

        batch.delete(docRef)
      })

      await batch.commit()
    } catch (error) {
      Logger.error('Error deleting proformas', error)
      throw ErrorService.get(ErrorCodes.ERROR_DELETING_PROFORMAS)
    }
  }

  //OrderPurchases
  async parseToOrderPurchaseBase(orderPurchaseFirebase: OrderPurchaseFirebaseType) {
    const {
      uid,
      name,
      description,
      createdAt,
      sequential,
      updatedAt,
      rawMaterials: rawMaterialsRefs,
    } = orderPurchaseFirebase

    const rawMaterials: OrderPurchaseType['rawMaterials'] = []

    for (const rawMaterialRef of rawMaterialsRefs) {
      const rawMaterialData = await parseRefToEntity<RawMaterialFirebaseType>(rawMaterialRef)

      const qtyRawMaterial = doc(
        firestore,
        Collections.QTY_ORDER_PURCHASES.NAME,
        getComposedIDs(uid, rawMaterialData.uid),
      )

      const qtyRawMaterialData = await parseRefToEntity<QtyBaseOrderType>(qtyRawMaterial)

      rawMaterials.push({
        ...rawMaterialData,
        quantity: qtyRawMaterialData.quantity,
      })
    }

    const orderPurchaseBase: OrderPurchaseType = {
      uid,
      name,
      description,
      createdAt,
      rawMaterials,
      sequential,
      updatedAt,
    }

    return orderPurchaseBase
  }

  async getOrderPurchases(params: GetOrderPurchasesType) {
    try {
      const collectionRef = collection(firestore, Collections.ORDER_PURCHASES.NAME)

      const queries: QueryConstraint[] = []

      if (params.cursorId) {
        const docRef = doc(collectionRef, params.cursorId)
        queries.push(startAfter(docRef))
        params.limit && queries.push(limit(params.limit))
      }

      queries.push(orderBy('updatedAt', params.orderDir ?? 'desc'))

      const q = query(collectionRef, ...queries)

      const orderPurchasesSnap = await getDocs(q)

      const orderPurchasesData = orderPurchasesSnap.docs.map((doc) =>
        doc.data(),
      ) as OrderPurchaseFirebaseType[]

      const orderPurchasesBase: OrderPurchaseType[] = []

      for (const orderPurchase of orderPurchasesData) {
        orderPurchasesBase.push(await this.parseToOrderPurchaseBase(orderPurchase))
      }

      return orderPurchasesBase
    } catch (error) {
      Logger.error('Error getting order purchases', error)
      throw ErrorService.get(ErrorCodes.ERROR_GETTING_ORDER_PURCHASES)
    }
  }

  async getOrderPurchaseById(id: string) {
    try {
      const docRef = doc(firestore, Collections.ORDER_PURCHASES.NAME, id)

      const orderPurchaseSnap = await getDoc(docRef)

      const orderPurchaseFirebaseData = orderPurchaseSnap.data() as OrderPurchaseFirebaseType

      return this.parseToOrderPurchaseBase(orderPurchaseFirebaseData)
    } catch (error) {
      Logger.error('Error getting order purchase by id', error)
      throw ErrorService.get(ErrorCodes.ERROR_GETTING_ORDER_PURCHASES)
    }
  }

  async createOrderPurchase(data: CreateOrderPurchaseType) {
    try {
      const orderPurchase = await runTransaction(firestore, async (transaction) => {
        const currentTimestamp = moment().unix()

        const uid = uuidv4()

        const sequential = await getSequential({ entity: 'orderPurchase' })

        const rawMaterialsRefs: DocumentReference[] = []

        for (const rawMaterial of data.rawMaterials) {
          const qtyRawMaterial = doc(
            firestore,
            Collections.QTY_ORDER_PURCHASES.NAME,
            getComposedIDs(uid, rawMaterial.uid),
          )

          const qtyRawMaterialData: QtyBaseOrderType = {
            orderID: uid,
            rawMaterialID: rawMaterial.uid,
            quantity: rawMaterial.quantity,
            createdAt: currentTimestamp,
            updatedAt: currentTimestamp,
          }

          transaction.set(qtyRawMaterial, qtyRawMaterialData)

          rawMaterialsRefs.push(doc(firestore, Collections.RAW_MATERIALS.NAME, rawMaterial.uid))
        }

        const newOrderPurchase: OrderPurchaseFirebaseType = {
          uid,
          sequential,
          name: data.name,
          description: data.description,
          createdAt: currentTimestamp,
          updatedAt: currentTimestamp,
          rawMaterials: rawMaterialsRefs,
        }

        transaction.set(doc(firestore, Collections.ORDER_PURCHASES.NAME, uid), newOrderPurchase)

        const orderPurchaseData: OrderPurchaseType = {
          uid,
          sequential,
          name: data.name,
          description: data.description,
          rawMaterials: data.rawMaterials,
          createdAt: currentTimestamp,
          updatedAt: currentTimestamp,
        }

        return orderPurchaseData
      })

      return orderPurchase
    } catch (error) {
      Logger.error('Error creating order purchase', error)
      throw ErrorService.get(ErrorCodes.ERROR_CREATING_ORDER_PURCHASE)
    }
  }

  async updateOrderPurchase(data: UpdateOrderPurchaseType) {
    try {
      const updateOrderPurchase: Partial<OrderPurchaseFirebaseType> = {}

      if (data.name) {
        updateOrderPurchase.name = data.name
      }

      if (data.description) {
        updateOrderPurchase.description = data.description
      }

      if (data.rawMaterials) {
        updateOrderPurchase.rawMaterials = data.rawMaterials.map((rawMaterial) =>
          doc(firestore, Collections.RAW_MATERIALS.NAME, rawMaterial.uid),
        )
      }

      if (Object.keys(updateOrderPurchase).length === 0) {
        throw ErrorService.get(ErrorCodes.ERROR_NOT_FIELDS_TO_UPDATE)
      }

      const docRef = doc(firestore, Collections.ORDER_PURCHASES.NAME, data.uid)

      await updateDoc(docRef, updateOrderPurchase)
    } catch (error) {
      Logger.error('Error updating order purchase', error)
      throw ErrorService.get(ErrorCodes.ERROR_UPDATING_ORDER_PURCHASE)
    }
  }

  async deleteOrderPurchase(id: string) {
    try {
      const docRef = doc(firestore, Collections.ORDER_PURCHASES.NAME, id)

      await deleteDoc(docRef)
    } catch (error) {
      Logger.error('Error deleting order purchase', error)
      throw ErrorService.get(ErrorCodes.ERROR_DELETING_ORDER_PURCHASE)
    }
  }

  async deleteOrderPurchases(ids: string[]) {
    try {
      const batch = writeBatch(firestore)

      ids.forEach((id) => {
        const docRef = doc(firestore, Collections.ORDER_PURCHASES.NAME, id)

        batch.delete(docRef)
      })

      await batch.commit()
    } catch (error) {
      Logger.error('Error deleting order purchases', error)
      throw ErrorService.get(ErrorCodes.ERROR_DELETING_ORDER_PURCHASES)
    }
  }

  //Export
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async parseEntityToXLSX(arrayOfArrays: any[][], filename: string) {
    const ws = XLSX.utils.aoa_to_sheet(arrayOfArrays)

    const wb = XLSX.utils.book_new()

    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1')

    XLSX.writeFile(wb, filename, {
      cellDates: true,
    })
  }

  parseClientToExport(snapClients: QuerySnapshot) {
    const clientsFirebase = snapClients.docs.map((doc) => doc.data()) as ClientFirebaseType[]

    const clientsBase: ClientBaseType[] = []

    for (const client of clientsFirebase) {
      clientsBase.push(this.clientEntityService.parseToClientBase(client))
    }

    const arrayOfArrays: string[][] = []

    arrayOfArrays.push([
      'ID',
      'Nombre',
      'RUC',
      'Correo',
      'Ubicación',
      'Descripción',
      'Recordatorio',
      'Contactos',
      'Prioridad',
      'Teléfono',
      'Actualizado',
      'Creado',
    ])

    for (const client of clientsBase) {
      arrayOfArrays.push([
        client.uid,
        client.name,
        client.description,
        client.ruc ?? client.phone ?? client.email ?? client.location ?? client.priority,
        client.remainder
          ? `${client.remainder?.date} - ${client.remainder?.repeat}`
          : 'Sin recordatorio',
        moment.unix(client.updatedAt).format(DATE_FORMAT_SHORT),
        moment.unix(client.createdAt).format(DATE_FORMAT_SHORT),
        client.contacts
          .map((contact) => `${contact.name} - ${contact.email} - ${contact.phone}`)
          .join('|'),
      ])
    }

    const sheets: ParseSheetsEntity[] = [
      {
        arrays: arrayOfArrays,
        sheetName: 'Clientes',
      },
    ]

    return sheets
  }

  parseRawMaterialToExport(snapRawMaterials: QuerySnapshot) {
    const rawMaterialsFirebase = snapRawMaterials.docs.map((doc) =>
      doc.data(),
    ) as RawMaterialFirebaseType[]

    const rawMaterialsBase: RawMaterialBaseType[] = []

    for (const rawMaterial of rawMaterialsFirebase) {
      rawMaterialsBase.push(this.constructionEntitiesService.parseToRawMaterialBase(rawMaterial))
    }

    const arrayOfArrays: string[][] = []

    arrayOfArrays.push([
      'ID',
      'Nombre',
      'Descripción',
      'Unidad',
      'Alerta de stock',
      'Precio',
      'Actualizado',
      'Creado',
      'Adjuntos',
    ])

    for (const rawMaterial of rawMaterialsBase) {
      arrayOfArrays.push([
        rawMaterial.uid,
        rawMaterial.name,
        rawMaterial.description,
        rawMaterial.measure,
        formatNumber(rawMaterial.alertStock, 0),
        formatNumber(rawMaterial.value, 2, '$'),
        moment.unix(rawMaterial.updatedAt).format(DATE_FORMAT_SHORT),
        moment.unix(rawMaterial.createdAt).format(DATE_FORMAT_SHORT),
        rawMaterial.attachments.join(' | '),
      ])
    }

    const sheets: ParseSheetsEntity[] = [
      {
        arrays: arrayOfArrays,
        sheetName: 'Materias primas',
      },
    ]

    return sheets
  }

  paseToolToExport(snapTools: QuerySnapshot) {
    const toolsFirebase = snapTools.docs.map((doc) => doc.data()) as RawMaterialFirebaseType[]

    const toolsBase: ToolBaseType[] = []

    for (const tool of toolsFirebase) {
      toolsBase.push(this.constructionEntitiesService.parseToToolBase(tool))
    }

    const arrayOfArrays: string[][] = []

    arrayOfArrays.push([
      'ID',
      'Nombre',
      'Descripción',
      'Unidad',
      'Alerta de stock',
      'Precio',
      'Actualizado',
      'Creado',
      'Adjuntos',
    ])

    for (const tool of toolsBase) {
      arrayOfArrays.push([
        tool.uid,
        tool.name,
        tool.description,
        formatNumber(tool.alertStock, 0),
        formatNumber(tool.value, 2, '$'),
        moment.unix(tool.updatedAt).format(DATE_FORMAT_SHORT),
        moment.unix(tool.createdAt).format(DATE_FORMAT_SHORT),
        tool.attachments.join(' | '),
      ])
    }

    const sheets: ParseSheetsEntity[] = [
      {
        arrays: arrayOfArrays,
        sheetName: 'Herramientas',
      },
    ]

    return sheets
  }

  calculateRawMaterialsValue(rawMaterials: RawMaterialBaseType[]) {
    return rawMaterials.reduce((acc, item) => acc + item.value, 0)
  }

  async parseReceiptToExport(snapReceipts: QuerySnapshot) {
    const receiptsFirebase = snapReceipts.docs.map((doc) => doc.data()) as ReceiptsFirebaseType[]

    const receipts: (ReceiptType & { value: number })[] = []

    for (const receipt of receiptsFirebase) {
      const receiptPartial = await this.constructionEntitiesService.parseToReceipt(receipt)

      receipts.push({
        ...receiptPartial,
        value: this.calculateRawMaterialsValue(receiptPartial.rawMaterials),
      })
    }

    const arrayOfArrays: string[][] = []

    arrayOfArrays.push([
      'ID',
      'Nombre',
      'Descripción',
      'Precio',
      'Materias primas',
      'Actualizado',
      'Creado',
    ])

    for (const receipt of receipts) {
      arrayOfArrays.push([
        receipt.uid,
        receipt.name,
        receipt.description,
        formatNumber(receipt.value, 2, '$'),
        receipt.rawMaterials
          .map((item) => `${item.name} - $${item.value} - Cantidad ${item.quantity}`)
          .join(' | '),
        moment.unix(receipt.updatedAt).format(DATE_FORMAT_SHORT),
        moment.unix(receipt.createdAt).format(DATE_FORMAT_SHORT),
      ])
    }

    const sheets: ParseSheetsEntity[] = [
      {
        arrays: arrayOfArrays,
        sheetName: 'Recetas',
      },
    ]

    return sheets
  }

  calculateReceiptsValue(receipts: ReceiptType[]) {
    return receipts.reduce(
      (acc, item) => acc + this.calculateRawMaterialsValue(item.rawMaterials),
      0,
    )
  }

  async parseBlockToExport(snapBlocks: QuerySnapshot) {
    const blocksFirebase = snapBlocks.docs.map((doc) => doc.data()) as BlockFirebaseType[]

    const blocks: (BlockType & { value: number })[] = []

    for (const block of blocksFirebase) {
      const blockPartial = await this.constructionEntitiesService.parseToBlock(block)

      blocks.push({
        ...blockPartial,
        value: this.calculateReceiptsValue(blockPartial.receipts),
      })
    }

    const arrayOfArrays: string[][] = []

    arrayOfArrays.push([
      'ID',
      'Nombre',
      'Descripción',
      'Precio',
      'Recetas', //must be calculated
      'Actualizado',
      'Creado',
    ])

    for (const block of blocks) {
      arrayOfArrays.push([
        block.uid,
        block.name,
        block.description,
        formatNumber(block.value, 2, '$'),
        block.receipts.map((item) => `${item.name} - $${item.quantity}`).join(' | '),
        moment.unix(block.updatedAt).format(DATE_FORMAT_SHORT),
        moment.unix(block.createdAt).format(DATE_FORMAT_SHORT),
      ])
    }

    const sheets: ParseSheetsEntity[] = [
      {
        arrays: arrayOfArrays,
        sheetName: 'Bloques',
      },
    ]

    return sheets
  }

  async parseProformaToExport(snapProformas: QuerySnapshot) {
    const proformasFirebase = snapProformas.docs.map((doc) => doc.data()) as ProformaFirebaseType[]

    const proformas: (ProformaBaseType & { subtotal: number; iva: number; total: number })[] = []

    for (const proforma of proformasFirebase) {
      const proformaPartial = await this.parseToProformaBase(proforma)

      const subtotal = proformaPartial.items.reduce(
        (acc, item) =>
          acc +
          getProformaTotal({
            item,
            itemsProforma: proformaPartial.items,
          }),
        0,
      )

      const iva = subtotal * (proformaPartial.iva / 100)

      const total = subtotal + iva

      proformas.push({
        ...proformaPartial,
        subtotal,
        iva,
        total,
      })
    }

    const sheets: ParseSheetsEntity[] = []

    for (const proforma of proformas) {
      const arrayOfArrays: (string | number)[][] = []

      arrayOfArrays.push(
        ...[
          ['Cotización', proforma.sequential],
          ['Cliente', proforma.client.name],
          ['Fecha de creación', moment.unix(proforma.createdAt).format(DATE_FORMAT_SHORT)],
          ['Vencimiento', `${proforma.dueDate.amount} ${proforma.dueDate.unit}`],
          ['Subtotal', formatNumber(proforma.subtotal, 2, '$')],
          ['IVA', formatNumber(proforma.iva, 2, '$')],
          ['Total', formatNumber(proforma.total, 2, '$')],
        ],
      )

      arrayOfArrays.push(['Items de la proforma'])

      arrayOfArrays.push(['ID', 'Nombre', 'Descripción', 'Cantidad', 'Precio unitario', 'Total'])

      for (const item of proforma.items) {
        arrayOfArrays.push([
          item.uid,
          item.name,
          item.description,
          item.quantity,
          formatNumber(
            getProformaTotal({ item, itemsProforma: proforma.items, unit: true }),
            2,
            '$',
          ),
          formatNumber(getProformaTotal({ item, itemsProforma: proforma.items }), 2, '$'),
        ])
      }

      sheets.push({
        arrays: arrayOfArrays,
        sheetName: `Proforma ${proforma.sequential}`,
      })
    }

    return sheets
  }

  fitToColumn(arrayOfArray: (string | number)[][]) {
    const uniqueValue = arrayOfArray[0]

    const columns = []

    for (let i = 0; i < uniqueValue.length; i++) {
      const array = []

      for (const a2 of arrayOfArray) {
        array.push(a2[i] ? a2[i].toString().length : 0)
      }

      const max = Math.max(...array)

      columns.push({ wch: max + 2 })
    }

    return columns
  }

  parseSheetsToXLSX(sheets: ParseSheetsEntity[], filename: string) {
    const wb = XLSX.utils.book_new()

    sheets.forEach(({ arrays, sheetName }) => {
      const ws = XLSX.utils.aoa_to_sheet(arrays)

      ws['!cols'] = this.fitToColumn(arrays)

      XLSX.utils.book_append_sheet(wb, ws, sheetName)
    })

    XLSX.writeFile(wb, filename, {
      cellDates: true,
      bookType: 'xlsx',
    })
  }

  async parseWarehouseRawMaterialsToExport(snapWarehouseRawMaterials: QuerySnapshot) {
    const warehouseRawMaterialsFirebase = snapWarehouseRawMaterials.docs.map((doc) =>
      doc.data(),
    ) as WarehouseFirebaseType[]

    const warehouses: WarehouseType[] = []

    for (const warehouse of warehouseRawMaterialsFirebase) {
      warehouses.push(await this.warehouseEntitiesService.parseToWarehouse(warehouse))
    }

    const sheets: ParseSheetsEntity[] = []

    for (const warehouse of warehouses) {
      const arrayOfArrays: (string | number)[][] = []

      arrayOfArrays.push(
        ...[
          ['Almacén', warehouse.name],
          ['Descripción', warehouse.description],
          ['Actualizado', moment.unix(warehouse.updatedAt).format(DATE_FORMAT_SHORT)],
          ['Creado', moment.unix(warehouse.createdAt).format(DATE_FORMAT_SHORT)],
        ],
      )

      arrayOfArrays.push(['Materias primas en stock'])

      arrayOfArrays.push([
        'ID',
        'Nombre',
        'Descripción',
        'Unidad',
        'Stock',
        'Alerta de stock',
        'Precio',
      ])

      for (const rawMaterial of warehouse.rawMaterials) {
        arrayOfArrays.push([
          rawMaterial.uid,
          rawMaterial.name,
          rawMaterial.description,
          rawMaterial.measure,
          rawMaterial.stock,
          formatNumber(rawMaterial.alertStock, 0),
          formatNumber(rawMaterial.value, 2, '$'),
        ])
      }

      sheets.push({
        arrays: arrayOfArrays,
        sheetName: `Bodega ${warehouse.name}`,
      })
    }

    return sheets
  }

  async parseWarehouseToolsToExport(snapWarehouseRawMaterials: QuerySnapshot) {
    const warehouseToolsFirebase = snapWarehouseRawMaterials.docs.map((doc) =>
      doc.data(),
    ) as WarehouseToolFirebaseType[]

    const warehouses: WarehouseToolType[] = []

    for (const warehouse of warehouseToolsFirebase) {
      warehouses.push(await this.warehouseEntitiesService.parseToWarehouseTool(warehouse))
    }

    const sheets: ParseSheetsEntity[] = []

    for (const warehouse of warehouses) {
      const arrayOfArrays: (string | number)[][] = []

      arrayOfArrays.push(
        ...[
          ['Almacén', warehouse.name],
          ['Descripción', warehouse.description],
          ['Actualizado', moment.unix(warehouse.updatedAt).format(DATE_FORMAT_SHORT)],
          ['Creado', moment.unix(warehouse.createdAt).format(DATE_FORMAT_SHORT)],
        ],
      )

      arrayOfArrays.push(['Herramientas en stock'])

      arrayOfArrays.push(['ID', 'Nombre', 'Descripción', 'Stock', 'Alerta de stock', 'Precio'])

      for (const tool of warehouse.tools) {
        arrayOfArrays.push([
          tool.uid,
          tool.name,
          tool.description,
          tool.stock,
          formatNumber(tool.alertStock, 0),
          formatNumber(tool.value, 2, '$'),
        ])
      }

      sheets.push({
        arrays: arrayOfArrays,
        sheetName: `Bodega ${warehouse.name}`,
      })
    }

    return sheets
  }

  async exportData(params: GetExportEntityType): Promise<void> {
    try {
      const {
        entity,
        filter: { allTimes, endDate, startDate },
      } = params

      let dateRange = 'Todo'

      if (startDate && endDate) {
        dateRange = `desde_${moment.unix(startDate).format(DATE_FORMAT_SHORT)}_hasta_${moment.unix(endDate).format(DATE_FORMAT_SHORT)}`
      }

      const collectionData = Collections[entity]

      const filename =
        `Exportación_${ExportEntities[entity].label}_${dateRange}_${moment().format('DD_MM_YYYY_HH_mm_ss')}.xlsx`.replaceAll(
          ' ',
          '_',
        )

      if (!collectionData) {
        throw ErrorService.get(ErrorCodes.ERROR_INVALID_ENTITY)
      }

      const collectionRef = collection(firestore, collectionData.NAME)

      const queries: QueryConstraint[] = []

      if (!allTimes) {
        if (startDate) {
          queries.push(where('updatedAt', '>=', startDate))
        }

        if (endDate) {
          queries.push(where('updatedAt', '<=', endDate))
        }
      }

      const q = query(collectionRef, ...queries)

      const docsSnap = await getDocs(q)

      let arrayOfArraysOfArrays: ParseSheetsEntity[] = []

      if (entity === 'CLIENTS') {
        arrayOfArraysOfArrays = this.parseClientToExport(docsSnap)
      } else if (entity === 'RAW_MATERIALS') {
        arrayOfArraysOfArrays = this.parseRawMaterialToExport(docsSnap)
      } else if (entity === 'TOOLS') {
        arrayOfArraysOfArrays = this.paseToolToExport(docsSnap)
      } else if (entity === 'RECEIPTS') {
        arrayOfArraysOfArrays = await this.parseReceiptToExport(docsSnap)
      } else if (entity === 'BLOCKS') {
        arrayOfArraysOfArrays = await this.parseBlockToExport(docsSnap)
      } else if (entity === 'PROFORMAS') {
        arrayOfArraysOfArrays = await this.parseProformaToExport(docsSnap)
      } else if (entity === 'WAREHOUSES') {
        arrayOfArraysOfArrays = await this.parseWarehouseRawMaterialsToExport(docsSnap)
      } else if (entity === 'WAREHOUSES_TOOLS') {
        arrayOfArraysOfArrays = await this.parseWarehouseToolsToExport(docsSnap)
      }

      if (arrayOfArraysOfArrays.length === 0) {
        throw ErrorService.get(ErrorCodes.ERROR_NO_DATA_TO_EXPORT)
      }

      this.parseSheetsToXLSX(arrayOfArraysOfArrays, filename)
    } catch (error) {
      Logger.error('Error exporting data', error)
      throw ErrorService.get(ErrorCodes.ERROR_EXPORTING_DATA)
    }
  }

  async exportBulkData(params: GetExportEntitiesBulkType) {
    try {
      const promises = params.map((param) => this.exportData(param))

      await Promise.all(promises)
    } catch (error) {
      Logger.error('Error exporting batch data', error)
      throw ErrorService.get(ErrorCodes.ERROR_EXPORTING_DATA)
    }
  }
}

export default DocumentEntitiesImpl

import {
  GetGeoCheckpointType,
  GeoCheckpointBaseType,
  CreateGeoCheckpointType,
} from '@customTypes/geoCheckpoint'

import { SignInType, SignUpType, UserType } from '@customTypes/user'

abstract class UserDS {
  abstract signinWithEmailAndPassword(params: SignInType): Promise<UserType>

  abstract signUpWithEmailAndPassword(params: SignUpType): Promise<UserType>

  abstract sendPasswordResetEmail(email: string): Promise<void>

  abstract getUser(): Promise<UserType | null>

  abstract getUsers(): Promise<UserType[]>

  abstract logout(): Promise<void>

  abstract deleteAccount(): Promise<void>

  abstract saveUserGeoCheckpoint(params: CreateGeoCheckpointType): Promise<void>

  abstract getGeoCheckpoints(params: GetGeoCheckpointType): Promise<GeoCheckpointBaseType[]>

  abstract checkBiometric(): Promise<boolean>
}

export default UserDS

import {
  GetProformasType,
  ProformaBaseType,
  CreateProformaType,
  UpdateProformaType,
} from '@customTypes/proforma'

import {
  OrderPurchaseType,
  GetOrderPurchasesType,
  CreateOrderPurchaseType,
  UpdateOrderPurchaseType,
} from '@customTypes/orderPurchase'

import { GetExportEntitiesBulkType, GetExportEntityType } from '@customTypes/export'

abstract class DocumentEntitiesDS {
  //Proformas
  abstract getProformas(params: GetProformasType): Promise<ProformaBaseType[]>

  abstract getProformaById(id: string): Promise<ProformaBaseType>

  abstract createProforma(data: CreateProformaType): Promise<ProformaBaseType>

  abstract updateProforma(data: UpdateProformaType): Promise<void>

  abstract deleteProforma(id: string): Promise<void>

  abstract deleteProformas(ids: string[]): Promise<void>

  //OrderPurchases
  abstract getOrderPurchases(params: GetOrderPurchasesType): Promise<OrderPurchaseType[]>

  abstract getOrderPurchaseById(id: string): Promise<OrderPurchaseType>

  abstract createOrderPurchase(data: CreateOrderPurchaseType): Promise<OrderPurchaseType>

  abstract updateOrderPurchase(data: UpdateOrderPurchaseType): Promise<void>

  abstract deleteOrderPurchase(id: string): Promise<void>

  abstract deleteOrderPurchases(ids: string[]): Promise<void>

  //Export
  abstract exportData(params: GetExportEntityType): Promise<void>

  abstract exportBulkData(params: GetExportEntitiesBulkType): Promise<void>
}

export default DocumentEntitiesDS

import {
  BlockBaseType,
  BlockType,
  CreateBlockType,
  DeleteReceiptsBlockType,
  GetBlocksType,
  UpdateBlockType,
} from '@customTypes/block'

import {
  RawMaterialWarehouseType,
  CreateRawMaterialBaseType,
  UpdateRawMaterialBaseType,
  GetRawMaterialsType,
  RawMaterialBaseType,
  GetRawMaterialWarehouseType,
  DeleteRawMaterialType,
  DeleteRawMaterialsType,
} from '@customTypes/rawMaterial'

import {
  CreateReceiptType,
  DeleteRawMaterialsFromReceiptType,
  GetReceiptsType,
  ReceiptBaseType,
  ReceiptType,
  UpdateReceiptType,
} from '@customTypes/receipt'

import {
  CreateToolBaseType,
  DeleteToolsType,
  DeleteToolType,
  GetToolsType,
  GetToolWarehouseType,
  ToolBaseType,
  ToolWarehouseType,
  UpdateToolBaseType,
} from '@customTypes/tool'

abstract class ConstructionEntitiesDS {
  abstract getRawMaterials(params: GetRawMaterialsType): Promise<RawMaterialBaseType[]>

  abstract getRawMaterialById(
    params: GetRawMaterialWarehouseType,
  ): Promise<RawMaterialWarehouseType>

  abstract getRawMaterialBaseById(id: string): Promise<RawMaterialBaseType>

  abstract createRawMaterial(params: CreateRawMaterialBaseType): Promise<RawMaterialBaseType>

  abstract updateRawMaterial(params: UpdateRawMaterialBaseType): Promise<void>

  abstract deleteRawMaterial(params: DeleteRawMaterialType): Promise<void>

  abstract deleteRawMaterials(params: DeleteRawMaterialsType): Promise<void>

  //Tool
  abstract getTools(params: GetToolsType): Promise<ToolBaseType[]>

  abstract getToolById(id: GetToolWarehouseType): Promise<ToolWarehouseType>

  abstract getToolBaseById(id: string): Promise<ToolBaseType>

  abstract createTool(params: CreateToolBaseType): Promise<ToolBaseType>

  abstract updateTool(params: UpdateToolBaseType): Promise<void>

  abstract deleteTool(params: DeleteToolType): Promise<void>

  abstract deleteTools(params: DeleteToolsType): Promise<void>

  //Receipts
  abstract getReceipts(params: GetReceiptsType): Promise<ReceiptBaseType[]>

  abstract getReceiptsExtended(params: GetReceiptsType): Promise<ReceiptType[]>

  abstract getReceiptById(id: string): Promise<ReceiptType>

  abstract createReceipt(params: CreateReceiptType): Promise<ReceiptType>

  abstract updateReceipt(params: UpdateReceiptType): Promise<void>

  abstract deleteReceipt(id: string): Promise<void>

  abstract deleteReceipts(ids: string[]): Promise<void>

  abstract deleteRawMaterialsFromReceipt(params: DeleteRawMaterialsFromReceiptType): Promise<void>

  //Blocks
  abstract getBlocks(params: GetBlocksType): Promise<BlockBaseType[]>

  abstract getBlocksExtended(params: GetBlocksType): Promise<BlockType[]>

  abstract getBlockById(id: string): Promise<BlockType>

  abstract createBlock(params: CreateBlockType): Promise<BlockType>

  abstract updateBlock(params: UpdateBlockType): Promise<void>

  abstract deleteBlock(id: string): Promise<void>

  abstract deleteBlocks(ids: string[]): Promise<void>

  abstract deleteReceiptsFromBlock(params: DeleteReceiptsBlockType): Promise<void>
}

export default ConstructionEntitiesDS

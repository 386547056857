import z from 'zod'

import { PriorityClient, RepeatReminder } from '@constants/client'

import { EventBaseSchema } from './event'
import { GetEntitySchema } from './network'
import { DocumentReference } from 'firebase/firestore'

export const PrioritySchema = z.custom<keyof typeof PriorityClient>(
  (value) => Object.keys(PriorityClient).includes(value),
  {
    message: 'Prioridad inválida',
  },
)

export type PriorityType = z.infer<typeof PrioritySchema>

export const RepeatReminderSchema = z.custom<keyof typeof RepeatReminder>(
  (value) => Object.keys(RepeatReminder).includes(value),
  {
    message: 'Recordatorio inválido',
  },
)

export type RepeatReminderType = z.infer<typeof RepeatReminderSchema>

export const ContactBaseSchema = z.object({
  uid: z.string(),
  name: z
    .string()
    .min(3, 'Nombre debe tener al menos 3 caracteres')
    .max(50, 'Nombre debe tener como máximo 50 caracteres')
    .trim(),
  email: z.string().email('Correo electrónico no válido').optional(),
  phone: z
    .string()
    .min(6, 'Teléfono debe tener al menos 6 caracteres')
    .max(10, 'Teléfono debe tener como máximo 10 caracteres')
    .trim()
    .optional(),
  master: z.boolean().optional().nullable(),
  updatedAt: z.number(),
})

export type ContactBaseType = z.infer<typeof ContactBaseSchema>

export const CreateContactSchema = ContactBaseSchema.omit({
  updatedAt: true,
})

export type CreateContactType = z.infer<typeof CreateContactSchema>

export const ClientBaseSchema = z.object({
  uid: z.string(),
  name: z
    .string()
    .min(3, 'Nombre debe tener al menos 3 caracteres')
    .max(50, 'Nombre debe tener como máximo 50 caracteres')
    .trim(),
  description: z.string().max(200, 'Descripción debe tener como máximo 200 caracteres').trim(),
  priority: PrioritySchema,
  //remainder: z.array(z.number()),
  remainder: z
    .object({
      repeat: RepeatReminderSchema,
      date: z.number(),
    })
    .optional()
    .nullable(),
  contacts: z.array(ContactBaseSchema).max(4, 'No puede tener más de 3 contactos'),
  updatedAt: z.number(),
  createdAt: z.number(),
  // Fields contact company level (used for proforma)
  email: z.string().email('Correo electrónico no válido').optional(),
  phone: z.string().max(10, 'Teléfono debe tener como máximo 10 caracteres').trim().optional(),
  ruc: z.string().max(14, 'RUC debe tener como máximo 14 caracteres').trim().optional(),
  location: z
    .string()
    .max(100, 'Ubicación debe tener como máximo 100 caracteres')
    .trim()
    .optional(),
})

export type ClientBaseType = z.infer<typeof ClientBaseSchema>

export const ClientSchema = ClientBaseSchema.extend({
  events: z.array(EventBaseSchema),
})

export type ClientType = z.infer<typeof ClientSchema>

// Internal use
export const ClientFirebaseSchema = ClientSchema.omit({
  events: true,
}).extend({
  events: z.array(z.custom<DocumentReference>()),
})

export type ClientFirebaseType = z.infer<typeof ClientFirebaseSchema>

// Create
export const CreateClientSchema = ClientBaseSchema.omit({
  uid: true,
  updatedAt: true,
  createdAt: true,
})

export type CreateClientType = z.infer<typeof CreateClientSchema>

// Update
export const UpdateClientSchema = ClientBaseSchema.omit({
  createdAt: true,
  updatedAt: true,
})

export type UpdateClientType = z.infer<typeof UpdateClientSchema>

// Get opportunities
export const GetClientsSchema = GetEntitySchema

export type GetClientsType = z.infer<typeof GetClientsSchema>

import {
  CommitBaseType,
  CommitType,
  CreateCommitType,
  GetCommitsType,
  UpdateCommitType,
} from '@customTypes/commit'

import { RawMaterialWarehouseType, GetRawMaterialsWarehouseType } from '@customTypes/rawMaterial'

import {
  CreateWarehouseType,
  DeleteRawMaterialsFromWarehouseType,
  GetWarehousesType,
  MoveRawMaterialsType,
  UpdateWarehouseType,
  WarehouseBaseType,
  WarehouseType,
} from '@customTypes/warehouse'

import { GetToolsWarehouseType, ToolWarehouseType } from '@customTypes/tool'

import {
  CreateWarehouseToolType,
  DeleteToolsFromWarehouseToolType,
  GetWarehouseToolsType,
  MoveToolsType,
  UpdateWarehouseToolType,
  WarehouseToolType,
} from '@customTypes/warehouseTool'
import {
  CommitToolBaseType,
  CommitToolType,
  CreateCommitToolType,
  GetCommitToolsType,
  UpdateCommitToolType,
} from '@customTypes/commitTool'

abstract class WarehouseEntitiesDS {
  //Warehouse Tool
  abstract getWarehouseTools(params: GetWarehouseToolsType): Promise<WarehouseBaseType[]>

  abstract getToolsByWarehouse(params: GetToolsWarehouseType): Promise<ToolWarehouseType[]>

  abstract getWarehouseToolById(id: string): Promise<WarehouseToolType>

  abstract createWarehouseTool(params: CreateWarehouseToolType): Promise<WarehouseToolType>

  abstract updateWarehouseTool(params: UpdateWarehouseToolType): Promise<void>

  abstract deleteWarehouseTool(id: string): Promise<void>

  abstract deleteWarehouseTools(ids: string[]): Promise<void>

  abstract moveToolsFromWarehouse(params: MoveToolsType): Promise<void>

  abstract deleteToolsFromWarehouse(params: DeleteToolsFromWarehouseToolType): Promise<void>

  //Commits Tools
  abstract getCommitsTool(params: GetCommitToolsType): Promise<CommitToolBaseType[]>

  abstract getCommitToolById(id: string): Promise<CommitToolType>

  abstract createCommitTool(params: CreateCommitToolType): Promise<CommitToolBaseType>

  abstract updateCommitTool(params: UpdateCommitToolType): Promise<void>

  abstract deleteCommitTool(id: string): Promise<void>

  abstract deleteCommitsTool(ids: string[]): Promise<void>

  //Warehouse
  abstract getWarehouses(params: GetWarehousesType): Promise<WarehouseBaseType[]>

  abstract getMaterialsByWarehouse(
    params: GetRawMaterialsWarehouseType,
  ): Promise<RawMaterialWarehouseType[]>

  abstract getWarehouseById(id: string): Promise<WarehouseType>

  abstract createWarehouse(params: CreateWarehouseType): Promise<WarehouseType>

  abstract updateWarehouse(params: UpdateWarehouseType): Promise<void>

  abstract deleteWarehouse(id: string): Promise<void>

  abstract deleteWarehouses(ids: string[]): Promise<void>

  abstract moveRawMaterials(params: MoveRawMaterialsType): Promise<void>

  abstract deleteRawMaterialsFromWarehouse(
    params: DeleteRawMaterialsFromWarehouseType,
  ): Promise<void>

  //Commits
  abstract getCommits(params: GetCommitsType): Promise<CommitBaseType[]>

  abstract getCommitById(id: string): Promise<CommitType>

  abstract createCommit(params: CreateCommitType): Promise<CommitBaseType>

  abstract updateCommit(params: UpdateCommitType): Promise<void>

  abstract deleteCommit(id: string): Promise<void>

  abstract deleteCommits(ids: string[]): Promise<void>
}

export default WarehouseEntitiesDS

import DataRepo from '@api/domain/repo/DataRepo'

import UserImpl from '@api/impl/ds/UserImpl'

import ClientEntityImpl from '@api/impl/ds/ClientEntityImpl'

import DocumentEntitiesImpl from '../ds/DocumentEntitiesImpl'

import WarehouseEntitiesImpl from '../ds/WarehouseEntitiesImpl'

import ConstructionEntitiesImpl from '../ds/ConstructionEntitiesImpl'

type ConstructorType = {
  userService: UserImpl
  clientEntityService: ClientEntityImpl
  documentEntitiesService: DocumentEntitiesImpl
  warehouseEntitiesService: WarehouseEntitiesImpl
  constructionEntitiesService: ConstructionEntitiesImpl
}

class DataRepoImpl extends DataRepo {
  userService: UserImpl
  clientEntityService: ClientEntityImpl
  documentEntitiesService: DocumentEntitiesImpl
  warehouseEntitiesService: WarehouseEntitiesImpl
  constructionEntitiesService: ConstructionEntitiesImpl

  constructor(services: ConstructorType) {
    super()

    this.userService = services.userService
    this.clientEntityService = services.clientEntityService
    this.documentEntitiesService = services.documentEntitiesService
    this.warehouseEntitiesService = services.warehouseEntitiesService
    this.constructionEntitiesService = services.constructionEntitiesService
  }
}

export default DataRepoImpl

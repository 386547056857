/* eslint-disable @typescript-eslint/no-unused-vars */
import { CACHED_TIME } from '@constants/app'
import { MutationCache, QueryCache, QueryClient } from '@tanstack/react-query'
import { createAsyncStoragePersister } from '@tanstack/query-async-storage-persister'

const queryClient = new QueryClient({
  queryCache: new QueryCache(),
  mutationCache: new MutationCache(),
})

queryClient.setDefaultOptions({
  queries: {
    retry: 2,
    gcTime: CACHED_TIME,
    // staleTime: ({ queryKey, state: { data, error, isInvalidated } }) => {
    //   if (error || isInvalidated) {
    //     return 0
    //   }

    //   if (Array.isArray(data)) {
    //     return data.length > 0 ? STALE_TIME : 0
    //   }

    //   if (data) {
    //     return STALE_TIME
    //   }

    //   return 0
    // },
    // staleTime: 0,
    networkMode: 'online',
    refetchOnMount: true,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  },
  mutations: {
    retry: 1,
    gcTime: 0,
    networkMode: 'online',
  },
})

export const asyncStoragePersister = createAsyncStoragePersister({
  storage: localStorage,
})

export default queryClient

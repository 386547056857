import UserImpl from '@api/impl/ds/UserImpl'
import ClientEntityImpl from '@api/impl/ds/ClientEntityImpl'
import DocumentEntitiesImpl from '@api/impl/ds/DocumentEntitiesImpl'
import WarehouseEntitiesImpl from '@api/impl/ds/WarehouseEntitiesImpl'
import ConstructionEntitiesImpl from '@api/impl/ds/ConstructionEntitiesImpl'

abstract class DataRepo {
  abstract userService: UserImpl

  abstract clientEntityService: ClientEntityImpl

  abstract documentEntitiesService: DocumentEntitiesImpl

  abstract warehouseEntitiesService: WarehouseEntitiesImpl

  abstract constructionEntitiesService: ConstructionEntitiesImpl
}

export default DataRepo

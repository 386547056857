import {
  CreateClientType,
  GetClientsType,
  ClientBaseType,
  UpdateClientType,
} from '@customTypes/client'

import {
  CreateEventType,
  DeleteEventsType,
  EventBaseType,
  GetEventsType,
  UpdateEventType,
} from '@customTypes/event'

abstract class ClientEntityDS {
  //Clients
  abstract getClients(params: GetClientsType): Promise<ClientBaseType[]>

  abstract getClientById(id: string): Promise<ClientBaseType>

  abstract createClient(client: CreateClientType): Promise<ClientBaseType>

  abstract updateClient(client: UpdateClientType): Promise<void>

  abstract deleteClient(id: string): Promise<void>

  abstract deleteClients(ids: string[]): Promise<void>

  abstract getEvents(params: GetEventsType): Promise<EventBaseType[]>

  abstract createEvent(params: CreateEventType): Promise<EventBaseType>

  abstract updateEvent(params: UpdateEventType): Promise<void>

  abstract deleteEvent(id: string): Promise<void>

  abstract deleteEvents(params: DeleteEventsType): Promise<void>
}

export default ClientEntityDS

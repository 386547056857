export const PriorityClient = {
  veryLow: {
    label: 'Muy baja',
    value: 'veryLow',
    color: 'red',
  },
  low: {
    label: 'Baja',
    value: 'low',
    color: 'orange',
  },
  medium: {
    label: 'Media',
    value: 'medium',
    color: 'blue',
  },
  high: {
    label: 'Alta',
    value: 'high',
    color: 'cyan',
  },
  veryHigh: {
    label: 'Muy alta',
    value: 'veryHigh',
    color: 'green',
  },
}

export const RepeatReminder = {
  never: {
    label: 'Nunca',
    value: 'never',
  },
  daily: {
    label: 'Diario',
    value: 'daily',
  },
  weekly: {
    label: 'Semanal',
    value: 'weekly',
  },
  monthly: {
    label: 'Mensual',
    value: 'monthly',
  },
  yearly: {
    label: 'Anual',
    value: 'yearly',
  },
}

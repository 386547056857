export const ExportEntities = {
  RAW_MATERIALS: {
    label: 'Materias primas',
    value: 'RAW_MATERIALS',
  },
  TOOLS: {
    label: 'Herramientas',
    value: 'TOOLS',
  },
  RECEIPTS: {
    label: 'Recetas',
    value: 'RECEIPTS',
  },
  BLOCKS: {
    label: 'Bloques',
    value: 'BLOCKS',
  },
  PROFORMAS: {
    label: 'Proformas',
    value: 'PROFORMAS',
  },
  CLIENTS: {
    label: 'Clientes',
    value: 'CLIENTS',
  },
  WAREHOUSES: {
    label: 'Bodegas de materia prima',
    value: 'WAREHOUSES',
  },
  WAREHOUSES_TOOLS: {
    label: 'Bodegas de herramientas',
    value: 'WAREHOUSES_TOOLS',
  },
}

import { UI } from '@constants/app'
import { useOs } from '@mantine/hooks'

export const useIsMobile = () => {
  const os = useOs()

  return os === 'ios' || os === 'android'
}

export const useTableMinWidth = (isMobile?: boolean, small?: boolean) => {
  return isMobile ? UI.TableMinWidthMobile : small ? UI.TableMinWidthSmall : UI.TableMinWidth
}

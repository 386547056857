import React from 'react'

export const usePagination = <T>(data: T[], initialLimit: number) => {
  const [page, setPage] = React.useState(1)
  const [limit, setLimit] = React.useState(initialLimit)
  const [totalPages, setTotalPages] = React.useState(Math.ceil(data.length / limit))
  const [totalElements, setTotalElements] = React.useState(data.length)

  const nextPage = () => {
    if (page < totalPages) {
      setPage(page + 1)
    }
  }

  const prevPage = () => {
    if (page > 1) {
      setPage(page - 1)
    }
  }

  const goToPage = (page: number) => {
    if (page > 0 && page <= totalPages) {
      setPage(page)
    }
  }

  React.useEffect(() => {
    setTotalPages(Math.ceil(data.length / limit))
    setTotalElements(data.length)
  }, [data, limit])

  return {
    page,
    limit,
    totalPages,
    totalElements,
    nextPage,
    prevPage,
    goToPage,
    setLimit,
    pageData: data.slice((page - 1) * limit, page * limit) as T[],
  }
}

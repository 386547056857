import DataRepositoryImpl from '@api/impl/repo/DataRepoImpl'

import UserImpl from '@api/impl/ds/UserImpl'
import ClientEntityImpl from '@api/impl/ds/ClientEntityImpl'
import DocumentEntitiesImpl from '@api/impl/ds/DocumentEntitiesImpl'
import WarehouseEntitiesImpl from '@api/impl/ds/WarehouseEntitiesImpl'
import ConstructionEntitiesImpl from '@api/impl/ds/ConstructionEntitiesImpl'

const userService = UserImpl.getInstance()
const clientEntityService = ClientEntityImpl.getInstance()
const documentEntitiesService = DocumentEntitiesImpl.getInstance()
const warehouseEntitiesService = WarehouseEntitiesImpl.getInstance()
const constructionEntitiesService = ConstructionEntitiesImpl.getInstance()

const DataRepo = new DataRepositoryImpl({
  userService,
  clientEntityService,
  documentEntitiesService,
  warehouseEntitiesService,
  constructionEntitiesService,
})

export default DataRepo

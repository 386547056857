import z from 'zod'

export const GetEntitySchema = z.object({
  limit: z.number().optional(),
  query: z.string().optional(),
  field: z.string().optional(),
  cursorId: z.string().optional(),
  orderDir: z.enum(['asc', 'desc']).optional(),
})

export type GetEntity = z.infer<typeof GetEntitySchema>

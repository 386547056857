export const Collections = {
  COMMITS: {
    NAME: 'commits-v1',
    ID: 'CM-',
  },
  COMMIT_TOOLS: {
    NAME: 'commit-tools-v1',
    ID: 'CT-',
  },
  TOOLS: {
    NAME: 'tools-v1',
    ID: 'TL-',
  },
  STOCKS_TOOLS: {
    NAME: 'stocks-tools-v1',
    ID: 'ST-',
  },
  WAREHOUSES_TOOLS: {
    NAME: 'warehouses-tools-v1',
    ID: 'WT-',
  },
  RAW_MATERIALS: {
    NAME: 'raw-materials-v1',
    ID: 'MP-',
  },
  STOCKS: {
    NAME: 'stocks-v1',
    ID: 'ST-',
  },
  WAREHOUSES: {
    NAME: 'warehouses-v1',
    ID: 'WH-',
  },
  RECEIPTS: {
    NAME: 'receipts-v1',
    ID: 'RE-',
  },
  QTY_RECEIPTS: {
    NAME: 'qty-receipts-v1',
    ID: 'QR-',
  },
  BLOCKS: {
    NAME: 'blocks-v1',
    ID: 'BK-',
  },
  QTY_BLOCKS: {
    NAME: 'qty-blocks-v1',
    ID: 'QB-',
  },
  PROFORMAS: {
    NAME: 'proforma-v1',
    ID: 'PF-',
  },
  COUNTERS: {
    NAME: 'counters-v1',
    ID: 'CT-',
  },
  CLIENTS: {
    NAME: 'client-v1',
    ID: 'CT-',
  },
  EVENTS: {
    NAME: 'events-v1',
    ID: 'EV-',
  },
  USERS: {
    NAME: 'users-v1',
    ID: 'US-',
  },
  GEO_CHECKPOINTS: {
    NAME: 'geo-checkpoints-v1',
    ID: 'GC-',
  },
  ORDER_PURCHASES: {
    NAME: 'order-purchases-v1',
    ID: 'OP-',
  },
  QTY_ORDER_PURCHASES: {
    NAME: 'qty-order-purchases-v1',
    ID: 'QO-',
  },
}

export const LIMIT_ITEMS = 10

export const GEOAPIFY_URL = 'https://api.geoapify.com/v1/geocode/reverse'
